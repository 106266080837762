import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import { I18NextService } from 'angular-i18next';

import { appService } from 'src/app/services/appService';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {

  @ViewChild('resultsStart') resultsStart!: ElementRef;

  public window!: Window;
  
  public pageData: any;
  public searchData: any;
  public p: number = 1;
  public showJumpTo: boolean = false;
  public showHeader: boolean = true;
  public searchError: boolean = false;
  public queryParams: any = {};
  public showTips: boolean = false;
  public emptySearch: boolean = false;
  private subscriptions: Subscription[] = [];
  private gotoStart: boolean = false;

  constructor(
    private appService: appService,
    private i18NextService: I18NextService,
    private router: Router,
    private route: ActivatedRoute
    ) { 
    this.setWindow(window);
  }

  public ngOnInit() {

    this.subscriptions.push(this.route.queryParams.subscribe((response) => { 
      if (response) {
        this.queryParams = response;
        if (this.queryParams.page) {
          this.appService.page.next(this.queryParams.page);
          this.p = +this.queryParams.page;
          const tParams: any = {...this.queryParams};
          delete tParams.page;
          this.queryParams = tParams;
          this.router.navigate([], { queryParams: this.queryParams, queryParamsHandling: ''});
        }
      }
    }));

    this.subscriptions.push(this.appService.searchTips.subscribe((response: any) => {
      this.showTips = response;
    }));

    this.subscriptions.push(this.appService.searchResults.subscribe((response: any) => {
      if (response !== 'error') {
        if (response === 'empty') {
          this.emptySearch = true;
          this.searchData = undefined;
        } else {
          this.emptySearch = false; 
          this.searchData = response;
          this.gotoStart = true;
          this.p = 1;
          
          if (this.resultsStart) {
            this.scrollToTopResults();
          }
        }
        this.appService.showLoading.next(false);
      } else {
        this.searchError = true;
      }
    }));

    this.updateContent(); 
  }

  @HostListener('document:scroll', ['$event'])
  public onViewportScroll() {
    if (this.searchData) {
      // bounding of search results element
      const boundingStart = this.resultsStart.nativeElement.getBoundingClientRect();

      // Toggle view of jump to button
      if (boundingStart.bottom <= -250) {
        if (!this.showJumpTo) {
          this.showJumpTo = true;
          this.appService.showJumpTo.next(true);
        }
      } else {
        if (this.showJumpTo) {
          this.showJumpTo = false;
          this.appService.showJumpTo.next(false);
        }
      }

      // Toggle view of header
      if (boundingStart.bottom <= -500) {
        if (this.showHeader) {
          this.showHeader = false;
          this.appService.showHeader.next(false);
        }
      } else {
        if (!this.showHeader) {
          this.showHeader = true;
          this.appService.showHeader.next(true);
        }
      }
    }
  }

  public setWindow(win: Window) {
    this.window = win;
  }

  public updateContent() {
    this.pageData = this.i18NextService.t('translations:i18_dissExpress');
  }

  public goToCart(url: string) {
    this.router.navigateByUrl(url);
  }

  public createCitation(title: any) {
    let citation = title.title + ' ' +
      this.pageData.by + ' ' + title.author + ', ' +
      title.schoolName + ', ' +
      title.yearPublished + ', ';
    if (title.pageCount && +title.pageCount > 0) {
      citation += title.pageCount + ' ' + this.pageData.pages + '; '
    }
    citation += title.pubNumber;

    // Clear the citation from any hidden html tags
    const div = document.createElement('div');
    div.innerHTML = citation;
    let cleanCitation = div.textContent || div.innerText || "";

    return cleanCitation;
  }

  public buildUrl(url: string) {
    let queryString = Object.entries(this.queryParams)
        .map(entry => entry.join('='))
        .join('&');
    if (this.p > 1) {
      queryString += `&page=${this.p}`;
    }
    if (queryString) {
      url += this.appService.rpath + encodeURIComponent(`?${queryString}`);
    }
    window.open(url, '_self');
  }

  public changePages(event: any) {
    this.p = event;
    this.appService.page.next(this.p);
    this.scrollToTopResults();
  }

  public toggleSearchTips() {
    this.appService.searchTips.next(this.appService.searchTips.value ? false : true);
  }

  private scrollToTopResults() {
    const resultsTop = this.resultsStart.nativeElement.getBoundingClientRect().top + window.scrollY - document.documentElement.clientTop;
    this.window.scroll({top: (resultsTop - 190), left: 0});
  }

}
