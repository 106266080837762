<div id="main">
  <form [formGroup]="searchForm">
    <div class="container content-space">
      <div class="row">
        <div class="col-12">
          <!-- Navbar -->
          <div class="navbar-expand-lg navbar-light">
            <div id="sidebarNav" class="navbar-vertical">

              <!-- Card -->
              <div class="card mb-5">
                <div id="searchTitleSection" class="card-header border-bottom d-flex justify-content-between">
                  <h2 class="card-header-title serif">{{ pageData?.search }}</h2>
                  <!-- Form Switch -->
                  <div class="d-flex justify-content-center">
                    <div class="form-check form-switch form-switch-between d-flex">
                      <label id="labelSearchToggle" for="searchToggle" class="form-check-label">{{ pageData?.searchTips }}</label>
                      <input 
                        id="searchToggle"
                        class="js-toggle-switch form-check-input"
                        type="checkbox"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target=".accordion-collapse"
                        aria-expanded="false"
                        formControlName = "searchTips"
                        (click)="toggleSearchTips()"
                        >
                    </div>
                  </div>
                  <!-- End Form Switch -->
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md mb-3 mb-md-0">
                      <label id="labelAuthor" for="searchAuthor" class="col-form-label form-label">{{ pageData?.author }}</label>
                      <div>
                        <input 
                          type="text"
                          class="form-control"
                          name="author"
                          id="searchAuthor"
                          placeholder="John Smith"
                          [attr.aria-label]="pageData.author"
                          formControlName="author"
                          value="">
                      </div>
                      <div id="helpAuthor" class="small accordion-collapse collapse mt-2 mt-2">
                        {{ pageData?.authorHelp }}
                      </div>
                    </div>
                    <div class="col-md mb-3 mb-md-0">
                      <label id="labelTitle" for="searchTitle" class="col-form-label form-label">{{ pageData?.title }}</label>
                      <div>
                        <input 
                          type="text"
                          class="form-control"
                          name="title"
                          id="searchTitle"
                          placeholder="A Dissertation About"
                          [attr.aria-label]="pageData?.title"
                          formControlName="title"
                          value="">
                      </div>
                      <div id="helpTitle" id="flush-collapse1" class="small accordion-collapse collapse mt-2">
                        {{ pageData?.titleHelp }}
                      </div>
                    </div>
                    <div class="col-md mb-3 mb-md-0">
                      <label id="labelKeyTerms" for="searchKeyTerms" class="col-form-label form-label">{{ pageData?.keyTerms }}</label>
                      <div class="">
                        <input
                          type="text"
                          class="form-control"
                          name="terms"
                          id="searchKeyTerms"
                          placeholder="Butterfly migration"
                          [attr.aria-label]="pageData?.keyTerms"
                          formControlName="terms"
                          value="">
                      </div>
                      <div id="helpKeyTerms" class="small accordion-collapse collapse mt-2">
                        {{ pageData?.keyTermsHelp }}
                      </div>
                    </div>
                    <div class="col col-md-auto mb-3 mb-md-0">
                      <label id="labelPubNumber" for="searchPubNumber" class="col-form-label form-label">{{ pageData?.pubNumber }}</label>
                      <div class="">
                        <input
                          type="text"
                          class="form-control mw-118"
                          name="pub"
                          id="searchPubNumber"
                          placeholder="12345678"
                          [attr.aria-label]="pageData?.pubNumber"
                          formControlName="pubNumber"
                          value="">
                      </div>
                      <div id="helpPubNumber" class="small accordion-collapse collapse mt-2 mw-118">
                        {{ pageData?.pubNumberHelp }}
                      </div>
                    </div>
                    <div class="col-md-auto mt-search">
                      <div class="d-grid">
                        <button class="btn btn-primary" (click)="runReport();">Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="serviceError" class="col-12">
                <div class="justify-content-center d-flex mt-10">
                  <div id="searchServiceError" class="alert alert-danger" role="alert">
                    <h3 class="alert-heading">{{ pageData?.errorTitle }}</h3>
                    <p [innerHTML]="pageData?.errorGeneric"></p>
                  </div>
                </div>
              </div>

            </div>
            <!-- End Card -->

          </div>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>
  </form>
  <div id="jumpToTop" [@fadeInOut]="showJumpTo ? 'fadeIn' : 'fadeOut'">
    <a class="go-to position-fixed" style="right: 2rem; bottom: 2rem;" (click)="jumpToTop();">
      <i class="bi-chevron-up"></i>
    </a>
  </div>
</div>

