import { Component, Input, OnInit } from '@angular/core';
import { appService } from 'src/app/services/appService';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { FormGroup, Validators, AbstractControl, FormBuilder, RequiredValidator } from '@angular/forms';

import { FadeInOutAnimation } from 'src/assets/styles/animations';

import { I18NextService } from 'angular-i18next';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  animations: [ FadeInOutAnimation ]
})
export class SearchComponent implements OnInit {

  public pageData: any;
  public serviceError: boolean = false;

  public searchForm!: FormGroup;
  public showJumpTo: boolean = false;
  public showTips: boolean = false;

  get author() { return this.searchForm.get('author'); }
  get title() { return this.searchForm.get('title'); }
  get terms() { return this.searchForm.get('terms'); }
  get pubNumber() { return this.searchForm.get('pubNumber'); }
  get searchTips() { return this.searchForm.get('searchTips'); }

  private subscriptions: Subscription[] = [];

  constructor(
    private appService: appService,
    private route: ActivatedRoute,
    private i18NextService: I18NextService,
    private fb: FormBuilder,
    private router: Router) { 
      this.createForm();
    }

  public ngOnInit() {
    this.subscriptions.push(this.route.queryParams.subscribe((response) => { 
      if (response) {
        const data:any = {};
        if (response.hasOwnProperty('author')) {
          data.author = response['author'];
          this.author?.setValue(data.author);
        }
        if (response.hasOwnProperty('title')) {
          data.title = response['title'];
          this.title?.setValue(data.title);
        }
        if (response.hasOwnProperty('terms')) {
          data.terms = response['terms'];
          this.terms?.setValue(data.terms);
        }
        if (response.hasOwnProperty('pubNum') || response.hasOwnProperty('pubNumber')) {
          data.pubNumber = response.hasOwnProperty('pubNum') ? response['pubNum'] : response['pubNumber'];
          this.pubNumber?.setValue(data.pubNumber);
        }
        if (Object.keys(data).length > 0) {
          this.executeReport(data);
        }
        // Remove all params after they've been pulled - enable this to keep a clean URL
        // this.router.navigate([], { queryParams: { }, queryParamsHandling: ''});
      }
    }));

    this.subscriptions.push(this.appService.searchTips.subscribe((response: any) => {
      this.showTips = response;
      this.searchTips?.setValue(this.showTips);
    }));

    this.subscriptions.push(this.appService.showJumpTo.subscribe((response: any) => {
      this.showJumpTo = response;
    }));
    
    this.updateContent();
  }

  public updateContent() {
    this.pageData = this.i18NextService.t('translations:i18_dissExpress');
  }

  public createForm() {
    this.searchForm = this.fb.group({
      author: '',
      title: '',
      terms: '',
      pubNumber: '',
      searchTips: '',
    });
  }

  public runReport () {
    if (!this.author?.value && !this.title?.value && !this.terms?.value && !this.pubNumber?.value){
      this.appService.searchResults.next('empty');
    } else {
      this.prepSearch(this.searchForm.getRawValue());
    }
  }

  public jumpToTop() {
    document.querySelector('#main')?.scrollIntoView({ behavior: 'smooth'});
  }

  public toggleSearchTips() {
    this.appService.searchTips.next(this.appService.searchTips.value ? false : true);
  }

  private prepSearch(searchData: any) {
    const search:any = [];
    search.author = searchData.author ? searchData.author : null;
    search.title = searchData.title ? searchData.title : null;
    search.terms = searchData.terms ? searchData.terms : null;
    search.pubNumber = searchData.pubNumber ? searchData.pubNumber : null;

    this.router.navigate([], { queryParams: search, queryParamsHandling: 'merge'});
  }

  private executeReport(searchData: any) {
    this.serviceError = false;
    this.appService.showLoading.next(true);

    this.subscriptions.push(this.appService.postSearchResults(searchData).subscribe(
      (response: HttpResponse<any>) => {
        this.appService.searchResults.next(response.body);
      },
      (error: HttpErrorResponse) => {
        this.appService.searchResults.next('error');
        this.appService.showLoading.next(false);
        this.serviceError = true;
      }
    ));
  }

}
