import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class appService {
  public showLoading = new BehaviorSubject<boolean>(true);
  public showHeader = new BehaviorSubject<boolean>(true);
  public showJumpTo = new BehaviorSubject<boolean>(false);
  public searchResults = new BehaviorSubject<any>(null);
  public searchTips = new BehaviorSubject<boolean>(false);
  public page = new BehaviorSubject<number>(1);
  public rpath = '';
  public downtimeMessage = new BehaviorSubject<string>('');
  
constructor(
  private http: HttpClient
  ) { 
    this.rpath = '&rpath=' + encodeURIComponent('https://' + location.hostname + (location.hostname === 'localhost' ? ':4200' : ''));
  }

  public postSearchResults(searchData: any){
    return this.http.post('services/dissexpress/search', searchData, {observe: 'response'});
  }

  public getAppValues() {
    return this.http.get('services/appValues', { observe: 'response'});
  }
}
