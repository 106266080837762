<footer class="bg-white start-md-0 end-md-0 bottom-md-0 w-100">
    <div class="container de-footer">
    <!-- Links -->
      <div class="row p-4 justify-content-between align-items-end gap-4">
        <div class="col-auto mb-0">
          <a class="" href="https://clarivate.com/" target="_blank">
            <div class="row align-items-end">
              <div class="col-auto p-0">
                <img class="avatar clarivate-logo" src="./assets/images/clarivate.svg" alt="{{ pageData?.clarivate }}">
              </div>
            </div>
          </a>
        </div>
        <div class="col-auto">
          <div class="row fw-500 justify-content-between gap-2">
            <div class="col-auto">
              <a class="text-black" href="https://clarivate.com/clarivate-proquest-acquisition/" target="_blank">{{ pageData?.proquestClarivate }}</a>
            </div>
            <div class="col-auto">
              <a class="text-black" href="https://about.proquest.com/go/orderdissertation" target="_blank">{{ pageData?.contactUs }}</a>
            </div>
            <div class="col-auto">
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{ pageData?.manageCookies }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-1 px-4 pb-4 justify-content-between justify-content-sm-center align-items-end fs-6 gap-2">
        <div class="col-12 col-sm-auto">
          <div class="row fw-500 justify-content-between">
            <div class="col-6 col-sm-auto">
              <a class="text-black" href="https://about.proquest.com/go/termsandconditions" target="_blank">{{ pageData?.termsConditions }}</a>
            </div>
            <div class="col-6 col-sm-auto">
              <a class="text-black" href="https://about.proquest.com/go/cookie" target="_blank">{{ pageData?.cookiePolicy }}</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-auto">
          <div class="row fw-500 justify-content-between">
            <div class="col-6 col-sm-auto">
              <a class="text-black" href="https://about.proquest.com/en/about/privacy-home/" target="_blank">{{ pageData?.privacyPolicy }}</a>
            </div>
            <div class="col-6 col-sm-auto">
              <a class="text-black" href="https://about.proquest.com/en/about/proquest-accessibility/" target="_blank">{{ pageData?.accessibility }}</a>
            </div>
          </div>
        </div>
        <div class="col-auto order-md-first">
          <span class="text-black fw-light">{{ pageData?.copyright }}</span>
        </div>
      </div>
    </div>
    <!-- End Row -->
  </footer>