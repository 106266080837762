import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Class used to load One Trust and Google Analytics scripts as string <script> tags.  Doing it this way to try and prevent any weird async issues, and to better match Clarivate's standard.
 */
@Injectable({
  providedIn: 'root'
})

export class OneTrustLoadService {

  constructor() {
    this.bindWrapperToWindow();
    this.loadOneTrust();
    this.loadGoogleTagManager();
  }

  public bindWrapperToWindow() {
    // making the service wrapper available on a global level, bound to an angular context.  Allows us to hit angular services outside of angular.
    window['OptanonWrapper'] = this.oneTrustServiceWrapper.bind(this);
  }

  /**
  * This should be the globaly avaliable function that the one trust callback hits.  Determines which cookies are enabled to setup or to remove the Google Tag Manager script
  */
  public oneTrustServiceWrapper() {
    const currentCookieValues = this.getCurrentCookieValues();
    if (currentCookieValues) {
      setTimeout(() => {
        this.reloadPageIfNeeded(currentCookieValues);
      }, 1000);
    }
  }

  /**
  * Determine if targeting or performace cookies were turned off by comparing their last two values.
  * If they were turned off (was true, now false), then reload the page to clear the GTM script from being enabled.
  */
  private reloadPageIfNeeded(currentCookieValues: { [x: string]: any; }) {
    const previousCookieValues: any = this.getPreviousCookieValues();
    if (previousCookieValues && ((previousCookieValues['performance'] && !currentCookieValues['performance']) || (previousCookieValues['functional'] && !currentCookieValues['functional']))) {
      location.reload();
    }
  }

  /**
  * Get the current cookie values from one trust
  */
  private getCurrentCookieValues() {
    const groupsArray = window.dataLayer.filter((item: any) => item.event === 'OneTrustGroupsUpdated');
    if (groupsArray.length > 0 && groupsArray[groupsArray.length - 1]['OnetrustActiveGroups']) {
      return this.getCookieValues(groupsArray[groupsArray.length - 1]);
    }
  }

  /**
  * Get the previous cookie values from one trust
  */
  private getPreviousCookieValues() {
    const groupsArray = window.dataLayer.filter((item: any) => item.event === 'OneTrustGroupsUpdated');
    if (groupsArray.length > 1 && groupsArray[groupsArray.length - 2].OnetrustActiveGroups) {
      return this.getCookieValues(groupsArray[groupsArray.length - 2]);
    }
  }

  /**
  * Get cookie values from the given one trust categories list
  */
  private getCookieValues(nonSplitCategoriesList: any) {
    const cookieValues: any = {};
    const categoriesList = nonSplitCategoriesList['OnetrustActiveGroups'].split(',');
    cookieValues['functional'] = categoriesList.some((value: string) => value === 'C0003');
    cookieValues['targeting'] = categoriesList.some((value: string) => value === 'C0004');
    cookieValues['performance'] = categoriesList.some((value: string) => value === 'C0002');
    return cookieValues;
  }

  /**
  * Manually load the one trust scripts
  */
  private loadOneTrust() {
    const cookieBannerId = environment.oneTrustKey;
    const script1 = document.createElement('script');
    script1.setAttribute('type', 'text/javascript');
    script1.setAttribute('src', `https://cdn.cookielaw.org/consent/${cookieBannerId}/OtAutoBlock.js`);

    const script2 = document.createElement('script');
    script2.setAttribute('type', 'text/javascript');
    script2.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    script2.setAttribute('charset', 'UTF-8');
    script2.setAttribute('data-domain-script', cookieBannerId);

    const header = document.getElementsByTagName('head')[0];
    if (header) {
      header.appendChild(script1);
      header.appendChild(script2);
    }
  }

  /**
  * Manually load the GTM script.  This is needed since it relies on one trust being loaded.  If this was loaded
  * from the index file, it would load before one trust.
  *
  * Change gtmKey value inside environment.{build}.ts to your to be your site's ID
  */
  private loadGoogleTagManager() {
    if (environment.gtmKey) {
      const gtmKey = environment.gtmKey;
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmKey}');`;

      const header = document.getElementsByTagName('head')[0];
      if (header) {
        header.appendChild(script);
      }
    }
  }

}
