<div class="container"> <!-- content-space-3 -->
  <div class="col-12 px-3" *ngIf="!searchData && !searchError">
    <div class=" greySection justify-content-center d-flex mt-10">
      <div>
        <h2 class="serif fw-bold">{{ emptySearch ? pageData?.oops : pageData?.getYourCopy }}
        </h2>
        <p>{{ pageData?.startSearch }}
        </p>
        <ul>
          <li>{{ pageData?.author }}</li>
          <li>{{ pageData?.title }}</li>
          <li>{{ pageData?.keyTerms }}</li>
          <li>{{ pageData?.pubNumber }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="searchData && !searchError">
    <div class="card mb-5">
      <div class="card-header border-bottom d-flex align-items-center justify-content-between">
        <div>
          <h2 class="card-header-title serif d-block d-sm-inline">{{ pageData?.results }}</h2>
          <span *ngIf="searchData.length > 0" class="small mx-sm-3">
            {{ pageData?.showing }} {{ searchData.length < 10 ? searchData.length : 10  }} {{ pageData?.of }} {{ searchData.length }} 
            <span #resulttooltip="matTooltip" matTooltip="{{ pageData?.resultsTooltip }}" [matTooltipPosition]="'above'" (click)="resulttooltip.toggle()">
              <i class="bi-info-circle-fill ms-1" data-bs-toggle="tooltip" data-bs-html="true" data-bs-placement="top"></i>
            </span>
          </span>
          <span *ngIf="searchData.length === 0" class="small mx-sm-3">
            {{ searchData.length }} {{ pageData?.results }}
          </span>
        </div>
        <div>
          <!-- Button trigger modal -->
            <button type="button" class="btn btn-sm btn-link" data-bs-toggle="modal" data-bs-target="#exampleModal">
              {{ pageData?.cantFindTitle }}
            </button>
            <!-- End Button trigger modal -->
            
            <ng-container *ngTemplateOutlet="helpModal"></ng-container>

        </div>
      </div>
      <div class="card-body pt-2">
        <div class="row mt-5 mt-md-0">
          <span id="resultsStart" class="resultsStart" #resultsStart></span>
          <ul class="de-row px-0">
            <li *ngIf="searchData.length === 0" class="list-group-item mb-10 my-md-5 mx-md-4">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <h4 class="text-center">{{ pageData?.noResults }}</h4>
                  </div>
                </div>
                <div class="row justify-content-md-center">
                  <div class="col-12 col-md-auto p-3 pt-4 p-md-5">
                    <div class="d-grid justify-content-center justify-content-md-end">
                      <button type="button" class="btn btn-primary btn-xs" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i class="bi-lightbulb-fill me-1"></i> {{ pageData?.learnWays }}
                      </button>
                    </div>
                  </div>
                  <div class="col-12 col-md-auto p-3 p-md-5">
                    <div class="d-grid justify-content-center justify-content-md-end">
                      <button class="btn btn-outline-primary btn-xs mt-2 mt-md-0" role="button" data-bs-toggle="collapse" data-bs-target=".accordion-collapse" aria-expanded="false" (click)="toggleSearchTips();">
                        <i *ngIf="showTips" class="bi-toggle-off me-1"></i> 
                        <i *ngIf="!showTips" class="bi-toggle-on me-1"></i> 
                        {{ showTips ? pageData?.turnOffTips : pageData?.turnOnTips }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li *ngFor="let title of searchData | paginate: { itemsPerPage: 10, currentPage: p }" class="list-group-item mb-10 my-md-5 mx-md-4">
              <div class="container">
                <div *ngIf="title.fullTextLink" class="row">
                  <div class="col-12 mb-1">
                    <span class="badge bg-soft-dark text-dark me-2 rounded-pill">{{ pageData?.openAccess }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md">
                    <div class="row">
                      <div class="col-12 mb-1">
                        <h4 [innerHTML]="title.title"></h4>
                      </div>
                      <div class="col-12 col-sm ps-5 mb-2">
                        <span class="d-block text-dark mb-2">{{ pageData?.by }} {{ title.author }}</span>
                        <small class="d-block text-muted">{{ title.schoolName }}, {{ title.yearPublished }}</small>
                      </div>
                      <div class="col-12 col-sm col-md-3 d-grid mb-4 ps-5">
                        <div class="d-grid align-content-between">
                          <span class="d-block text-dark mb-2">
                            <span *ngIf="title.pageCount && +title.pageCount > 0">
                              {{ title.pageCount }} {{ pageData?.pages }}
                            </span>
                          </span>
                          <div>
                            <small class="d-block text-muted">{{ title.pubNumber }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-auto ps-5">
                    <div class="d-grid justify-content-start justify-content-md-end">
                      <button type="button" *ngIf="title.orderCopyLink" class="btn btn-primary btn-xs" (click)="buildUrl(title.orderCopyLink);">
                        <i class="bi-cart-fill me-1"></i> {{ pageData?.addCart }}
                      </button>
                      <a *ngIf="title.fullTextLink" href="{{ title.fullTextLink }}" target="_blank">
                        <button class="btn btn-outline-primary btn-xs mt-2" data-bs-toggle="modal" data-bs-target="#accountEditCardModal">
                          <i class="bi-box-arrow-up-right me-1"></i> {{ pageData?.downloadPDF }}
                        </button>
                      </a>
                      <div #{{title.pubNumber}}tooltip="matTooltip" *ngIf="!title.orderCopyLink" class="btn-link btn-xs" matTooltip="{{ pageData?.notAvailableHelp }}" [matTooltipPosition]="'above'">
                        <i class="bi-info-circle me-1"></i> {{ pageData?.notAvailable }}
                      </div>
                      <button type="button" class="btn btn-link btn-xs mt-2" [cdkCopyToClipboard]="createCitation(title)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy me-1" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"/>
                        </svg>
                        {{ pageData?.copyCitation }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div *ngIf="searchData.length > 0" class="card-footer pt-0">
          <div class="d-flex justify-content-center gap-3">
            <pagination-controls 
              (pageChange)="changePages($event);"
              previousLabel="{{ pageData?.previous }}"
              nextLabel="{{ pageData?.next }}"
              >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #helpModal>
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ pageData?.helpTitle }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- Table -->
          <h3>{{ pageData?.generalTipsTitle }}</h3>
          <ul>
            <li>{{ pageData?.generalTips1 }}</li>
            <li>{{ pageData?.generalTips2 }}</li>
          </ul>
          <h3>{{ pageData?.operatorsTitle }}</h3>
          <p [innerHTML]="pageData?.operatorsText"></p>
          <ol>
            <li>{{ pageData?.operatorsPre }}</li>
            <li>{{ pageData?.operatorsNear }}</li>
            <li>{{ pageData?.operatorsAnd }}</li>
            <li>{{ pageData?.operatorsOr }}</li>
            <li>{{ pageData?.operatorsNot }}</li>
          </ol>

          <table class="table table-sm table-vertical-border-striped">
            <thead class="thead-light table-text-center">
              <tr>
                <th scope="col">{{ pageData?.operator }}</th>
                <th scope="col">{{ pageData?.description }}</th>
                <th scope="col">{{ pageData?.example }}</th>
              </tr>
            </thead>
            <tbody class="table-align-middle">
              <tr>
                <th scope="row" class="text-dark fw-bold">{{ pageData?.operatorsAnd }}</th>
                <td class="table-text-center" [innerHTML]="pageData?.operatorsAndDescription"></td>
                <td class="table-text-center">{{ pageData?.operatorsAndExample }}</td>
              </tr>

              <tr>
                <th scope="row" class="text-dark fw-bold">{{ pageData?.operatorsOr }}</th>
                <td class="table-text-center" [innerHTML]="pageData?.operatorsOrDescription"></td>
                <td class="table-text-center">{{ pageData?.operatorsOrExample }}</td>
              </tr>

              <tr>
                <th scope="row" class="text-dark fw-bold">{{ pageData?.operatorsNot }}</th>
                <td class="table-text-center">{{ pageData?.operatorsNotDescription }}</td>
                <td class="table-text-center">{{ pageData?.operatorsNotExample }}</td>
              </tr>

              <tr>
                <th scope="row" class="text-dark fw-bold">{{ pageData?.operatorsNear }}/n</th>
                <td class="table-text-center">{{ pageData?.operatorsNearDescription }}</td>
                <td class="table-text-center">{{ pageData?.operatorsNearExample }}</td>
              </tr>

              <tr>
                <th scope="row" class="text-dark fw-bold">{{ pageData?.operatorsPre }}/n</th>
                <td class="table-text-center">{{ pageData?.operatorsPreDescription }}</td>
                <td class="table-text-center">{{ pageData?.operatorsPreExample }}</td>
              </tr>

              <tr>
                <th scope="row" class="text-dark fw-bold">*</th>
                <td class="table-text-center">{{ pageData?.operatorsAsteriskDescription }}</td>
                <td class="table-text-center" [innerHTML]="pageData?.operatorsAsteriskExample"></td>
              </tr>

              <tr>
                <th scope="row" class="text-dark fw-bold">" "</th>
                <td class="table-text-center" [innerHTML]="pageData?.operatorsQuoteDescription"></td>
                <td class="table-text-center" [innerHTML]="pageData?.operatorsQuoteExample"></td>
              </tr>

            </tbody>
          </table>
          <!-- End Table -->

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">{{ pageData?.close }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>