import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { bootstrapApplication } from '@angular/platform-browser';
import { HeaderComponent } from './pagecomponents/header/header.component';
import { SearchComponent } from './pagecomponents/search/search.component';

const routes: Routes = [
  {path: "main", component:HeaderComponent},
  {path: "search", component:SearchComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }