import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from 'ngx-pagination';
import {NgxPopperjsModule} from 'ngx-popperjs';
import {CookieService} from 'ngx-cookie-service';

import { I18NEXT_SERVICE, I18NextLoadResult, I18NextModule, ITranslationService } from 'angular-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { HeaderComponent } from './pagecomponents/header/header.component';
import { SearchComponent } from './pagecomponents/search/search.component';
import { ResultsComponent } from './pagecomponents/results/results.component';
import { FooterComponent } from './pagecomponents/footer/footer.component';
import { MaintenanceComponent } from './pagecomponents/maintenance/maintenance.component';

const i18nextOptions = {
  whitelist: ['en', 'es', 'fr-ca', 'fr'],
  fallbackLng: 'en',
  nonExplicitWhitelist: false,
  lowerCaseLng: true,
  debug: false, // set debug?
  returnEmptyString: false,
  returnObjects: true,
  ns: [
    'translations'
  ],
  interpolation: {
    format: I18NextModule.interpolationFormat(),
    defaultVariables: {
      space: '\u00A0'
    }
  },
  // backend plugin options
  backend: {
    loadPath: '/assets/translation/{{lng}}.{{ns}}.json'
  },
  
  // lang detection plugin options
  detection: {
    // order and from where user language should be detected
    order: ['cookie', 'navigator', 'querystring'],

    // keys or params to lookup language from
    lookupCookie: 'lang',

    // cache user language on
    caches: ['cookie'],

    // optional expire and domain for set cookie
    cookieMinutes: 10080, // 7 days
    // cookieDomain: I18NEXT_LANG_COOKIE_DOMAIN
    cookieDomain: (location.hostname !== 'localhost') ? '.' + location.hostname.replace('www.', '') : location.hostname
  }
};

export function appInit(i18next: ITranslationService) {
  return () => {
    const promise: Promise<I18NextLoadResult> = i18next
      .use(HttpApi)
      .use(LanguageDetector)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SearchComponent,
    ResultsComponent, 
    FooterComponent,
    MaintenanceComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    I18NextModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgxPaginationModule,
    MatTooltipModule,
    NgxPopperjsModule
  ],
  providers: [I18N_PROVIDERS, CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
