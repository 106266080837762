import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Inject, Self, inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, takeUntil } from 'rxjs';
import { OneTrustLoadService } from './services/external-load.service';

import { appService } from 'src/app/services/appService';

declare global {
  interface Window { dataLayer: any, OptanonWrapper: any }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Dissertations Express';

  private subscriptions: Subscription[] = [];

  constructor(
    private OneTrustLoadService: OneTrustLoadService, // injecting here to initalize it
    private spinner: NgxSpinnerService,
    private appService: appService,
    
    ) {}
  
  public ngOnInit() {
    
    this.subscriptions.push(this.appService.showLoading.subscribe((response: any) => {
      if (response) { 
        this.spinner.show(); 
      } else {
        this.spinner.hide();
      }
      /*
      setTimeout(() => {
        // spinner ends after 5 seconds
        this.spinner.hide();
      }, 5000);
      */
    }))
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public ngAfterViewInit() {
    // document.querySelector('html').setAttribute('lang', this.i18NextService.language);
  }
}
