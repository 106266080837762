<header *ngIf="showHeader" [@slideInTop] id="header" class="navbar navbar-expand-lg navbar-end navbar-sticky-top navbar-light navbar-show-hide navbar-toggle"
          data-hs-header-options='{
            "fixMoment": 1000,
            "fixEffect": "slide"
          }'>
    <div class="container">
      <nav class="js-mega-menu navbar-nav-wrap">
        <!-- Default Logo -->
        <a id="logoLink" href="#" class="navbar-brand" [attr.aria-label]="pageData?.dissExpress">
          <span class="pq-nav text-white serif">{{ pageData?.proquest }}</span>
          <h1 class="h2 text-white de-nav">{{ pageData?.dissExpress }}</h1>
        </a>
        <!-- End Default Logo -->

        <!-- Toggler -->
        <button 
          class="navbar-toggler"
          type="button"
          id="navToggle"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" 
          aria-expanded="false" 
          [attr.aria-label]="pageData?.toggleNavigation">
          <span class="navbar-toggler-default">
            <i class="bi-list"></i>
          </span>
          <span class="navbar-toggler-toggled">
            <i class="bi-x"></i>
          </span>
        </button>
        <!-- End Toggler -->

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <div class="navbar-sticky-top-scroller">
            <ul class="navbar-nav">
              <!-- Help -->
              <li class="hs-has-mega-menu nav-item">
                <a id="helpMegaMenu" 
                  class="nav-link" 
                  aria-current="page" 
                  role="button" 
                  aria-expanded="false" 
                  [attr.aria-label]="pageData?.help"
                  (click)="toggleHelp();" 
                  (mouseenter)="setShowHelp(!showMobile);" 
                  (mouseleave)="setHideHelp(!showMobile);"
                  >
                    {{ pageData?.help }}
                    <i class="bi-caret-down-fill ms-2"></i>
                </a>
              </li>
              <div *ngIf="showHelp" id="helpHoverSectionM" class="mobileView">
                <ng-container *ngTemplateOutlet="helpMenu"></ng-container>
              </div>
              <!-- End Help -->



              <!-- Pricing -->
              <li class="hs-has-mega-menu nav-item">
                <a id="pricingMegaMenu" 
                  class="nav-link" 
                  aria-current="page" 
                  role="button" 
                  aria-expanded="false"
                  [attr.aria-label]="pageData?.pricing"
                  (click)="togglePricing();" 
                  (mouseenter)="setShowPricing(!showMobile);" 
                  (mouseleave)="setHidePricing(!showMobile);">
                    {{ pageData?.pricing }}
                    <i class="bi-caret-down-fill ms-2"></i>
                </a>
              </li>
              <div *ngIf="showPricing" id="pricingHoverSection" class="mobileView">
                <ng-container *ngTemplateOutlet="pricingMenu" [@collapse]="showPricing"></ng-container>
              </div>
              <!-- End Pricing -->


              <!-- Button -->
              <li class="nav-item">
                <a class="btn btn-primary" 
                  id="cartLink"
                  href="{{ iStoreURL() }}"
                  [attr.aria-label]="pageData?.cart">
                  <i class="bi-cart-fill me-1"></i> {{ pageData?.cart }}
                </a>
              </li>
              <!-- End Button -->
            </ul>
            
          </div>
          
        </div>

          <div *ngIf="!showMobile && showHelp" id="helpHoverSection" class="infoHover standardView" (mouseenter)="hoverActive = true;" (mouseleave)="this.hoverActive = false; setHideHelp(!showMobile);">
            <ng-container *ngTemplateOutlet="helpMenu"></ng-container>
          </div>
          <div *ngIf="!showMobile && showPricing" id="pricingHoverSection" class="infoHover standardView" (mouseenter)="hoverActive = true;" (mouseleave)="this.hoverActive = false; setHidePricing(!showMobile);">
            <ng-container *ngTemplateOutlet="pricingMenu"></ng-container>
          </div>

        <!-- End Collapse -->
      </nav>
      
    </div>
  </header>

<div *ngIf="showToast" id="liveToast" class="position-fixed toast show" role="alert" aria-live="assertive" aria-atomic="true" style="z-index: 1000;">
  <div class="toast-header">
    <div class="d-flex align-items-center flex-grow-1">
      <div class="flex-shrink-0 bg-soft-success badge avatar-circle">
        <i class="h3 text-success bi-currency-dollar"></i>
      </div>
      <div class="flex-grow-1 ms-3">
        <h5 class="mb-0">{{ pageData?.authorDiscount }}</h5>
      </div>
      <div class="text-end">
        <button type="button" class="btn-close" data-bs-dismiss="toast" [attr.aria-label]="pageData?.close" (click)="hideToast();"></button>
      </div>
    </div>
  </div>
  <div class="toast-body" [innerHTML]="pageData?.seePricing">
  </div>
</div>

<ng-template #helpMenu>
  <div class="hs-mega-menu dropdown-menu w-100 de-navbar-border" aria-labelledby="helpMegaMenu" style="display:flex; min-width: 30rem;">
    <div class="row justify-content-center">
      <div class="col-lg-6 p-lg-2 px-lg-5" style="background-image: url(./assets/images/se-card-1.svg);background-size: auto 100%">
        <div class="navbar-dropdown-menu-inner">
          <div class="h3 d-inline">{{ pageData?.libraries }}</div>
          <ul class="list-group list-group-sm mt-4 fw-semibold">
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/What-is-Dissertation-Express-for-ILL" target="_blank">{{ pageData?.illDissExpress }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/Dissertation-Express-for-ILL-Account-Guidelines" target="_blank">{{ pageData?.illGuidelines }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://proquest.iad1.qualtrics.com/jfe/form/SV_6A4TbvvbOl64ULQ" target="_blank">{{ pageData?.illRequest }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/Dissertation-Express-How-Can-I-Modify-My-Account-Address" target="_blank">{{ pageData?.changeAddress }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/Can-a-school-library-purchase-copies-of-other-graduate-works" target="_blank">{{ pageData?.other }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6 p-lg-2 px-lg-5">
        <div class="navbar-dropdown-menu-inner">
          <div class="h3">{{ pageData?.general }}</div>
          <ul class="list-group list-group-sm mt-4 fw-semibold">
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/Order-production-shipping-information" target="_blank">{{ pageData?.shipping }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://about.proquest.com/en/dissertations/dissertation-copy-options/" target="_blank">{{ pageData?.printed }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/Credit-card-purchase-failing-for-Dissertation-products" target="_blank">{{ pageData?.helpCC }}</a>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <a href="https://support.proquest.com/s/article/dissertation-ordering-problems?language=en_US" target="_blank">{{ pageData?.trouble }}</a>
            </li>
          </ul>
          <div class="fst-italic fs-6 mt-3 text-center">{{ pageData?.supportedBrowsers }}</div>
        </div>
      </div>
      <div class="col-auto pt-3 pb-4">
        <a href="https://support.proquest.com/s/product/dissertations-etd" class="btn btn-outline-primary btn-sm rounded-pill" target="_blank">{{ pageData?.callChat }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pricingMenu>
  <div class="hs-mega-menu dropdown-menu w-100 de-navbar-border" aria-labelledby="pricingMegaMenu" style="display:flex; min-width: 30rem;">
    <div class="row">
      <div class="col-lg-6 p-lg-2 px-lg-5" style="background-image: url(./assets/images/se-card-1.svg);background-size: auto 100%">
        <div class="navbar-dropdown-menu-inner">
          <div class="h3 d-inline">{{ pageData?.authorPricing }}</div>
          <div class="badge bg-soft-success text-success ms-2 d-inline align-text-top">{{ pageData?.discount }}</div>
          <p class="mt-4 text-dark">{{ pageData?.authorDiscountHelp }}</p>
          <ul class="nav-list">
            <li class="mb-4 text-dark" [innerHTML]="pageData?.helpLocal"></li>
            <li class="mb-4 text-dark" [innerHTML]="pageData?.helpOther"></li>
          </ul>
        </div>
      </div>
      <!-- End Col -->

      <div class="col-lg-6 p-lg-2 px-lg-5">
        <div class="navbar-dropdown-menu-inner">
          <div class="h3">{{ pageData?.publicPricing }}</div>
          <ul class="list-group list-group-sm mt-4">
            <li class="list-group-item d-flex align-items-center">
              <span class="fw-semibold me-2">{{ pageData?.pdf }}</span> {{ pageData?.download }}
              <span class="badge bg-soft-info text-info ms-2">{{ pageData?.fastest }}</span>
              <span class="badge de-dark-blue rounded-pill ms-auto">$41</span>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <span class="fw-semibold me-2">{{ pageData?.softcover }}</span> {{ pageData?.size6x9 }}
              <span class="badge de-dark-blue rounded-pill ms-auto">$58</span>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <span class="fw-semibold me-2">{{ pageData?.softcover }}</span> {{ pageData?.size8x11 }}
              <span class="badge de-dark-blue rounded-pill ms-auto">$65</span>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <span class="fw-semibold me-2">{{ pageData?.hardcover }}</span> {{ pageData?.size6x9 }}
              <span class="badge de-dark-blue rounded-pill ms-auto">$77</span>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <span class="fw-semibold me-2">{{ pageData?.hardcover }}</span> {{ pageData?.size8x11 }}
              <span class="badge bg-soft-dark text-dark ms-2">{{ pageData?.libraryCongress }}</span>
              <span class="badge de-dark-blue rounded-pill ms-auto">$82</span>
            </li>
            <li class="list-group-item d-flex align-items-center">
              <span class="fw-semibold me-2">{{ pageData?.hardcover }}</span> {{ pageData?.sizeA4 }}
              <span class="badge de-dark-blue rounded-pill ms-auto">$82</span>
            </li>
          </ul>
          <div class="fst-italic fs-6 mt-3 text-center">{{ pageData?.chooseFormat }}</div>
        </div>
      </div>
    </div>
    <!-- End Row -->
  </div>
  <!-- End Mega Menu -->
</ng-template>