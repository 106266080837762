import { Component, OnInit } from '@angular/core';
import { I18NextService } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { appService } from 'src/app/services/appService';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  public messageText = '';

  private subscriptions: Subscription[] = [];

  constructor(
    private appService: appService,
    private i18NextService: I18NextService,
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.appService.downtimeMessage.subscribe((response: any) => {
      this.messageText = this.appService.downtimeMessage.value;
    }))
  }

}
