import { trigger, state, transition, style, animate, AUTO_STYLE } from '@angular/animations';

const DEFAULT_DURATION = 500;

export const SlideInTopAnimation = [
  trigger('slideInTop', [
    transition(':enter', [
      style({ transform: 'translateY(-100%)' }),
      animate('500ms ease-out', style({ transform: 'translateY(0)' })),
    ]),
    transition(":leave", [
      animate('500ms ease-out', style({ transform: 'translateY(-100%)' })),
    ])
  ]),
]

export const Collapse = [
  trigger('collapse', [
    state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
    state('true', style({ height: '0', visibility: 'hidden' })),
    transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
    transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
  ])
]

export const FadeInOutAnimation = 
[trigger('fadeInOut', [
    state('fadeIn', style({ opacity: 1 })),
    state('fadeOut', style({ opacity: 0 })),
    transition('* => fadeOut', animate('500ms ease-in')),
    transition('* => fadeIn', animate('500ms ease-out'))
  ]),
]