import { Component, OnInit } from '@angular/core';
import { I18NextService } from 'angular-i18next';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public pageData: any;

  constructor(
    private i18NextService: I18NextService
  ) { }

  public ngOnInit() {
    this.updateContent();
  }

  public updateContent() {
    this.pageData = this.i18NextService.t('translations:i18_dissExpress', {year: (new Date()).getFullYear() });
  }

}
